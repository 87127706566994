import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import { TitleSection, BlogImage, Description } from "../../../components/BlogComponents";
import display_parts from "../../../images/display_parts.jpg";
import assembled from "../../../images/display_assembled.jpg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This is the start of a new project that I'm figuring out as I go. My end goal is to build a handheld air quality meter built off a Raspberry Pi, but there are plenty of steps between here and there that I'll have to get through first.`}</p>
    <h2 {...{
      "id": "background"
    }}><a parentName="h2" {...{
        "href": "#background"
      }}>{`Background`}</a></h2>
    <p>{`As a bit of background, I have pretty bad asthma, that's been getting worse every year do to California's worrying trend of having terrible, multi-week forest fires every year now. "Fire season" has always been a familiar phrase here, but growing up, it was more of a threat, and less a promise. I recommend reading about how indigenous peoples in California, including Yurok, Karuk, Hupa, Miwok, Chumash tribes, have a tradition of using `}<a parentName="p" {...{
        "href": "https://www.theguardian.com/us-news/2019/nov/21/wildfire-prescribed-burns-california-native-americans"
      }}>{`controlled burns`}</a>{` that managed the fires and fostered the beautiful forests here, before California outlawed the practice because colonialism.`}</p>
    <p>{`Anyway, I've become fairly reliant on the `}<a parentName="p" {...{
        "href": "http://purpleair.com/"
      }}>{`PurpleAir`}</a>{` website, where people can buy a fairly affordable (\\$230 - but this kind of thing used to cost way more) wifi-connected air quality meter that gets shared out to a map, which shares real-time air quality that often shares results that are within a couple blocks from you if a neighbor decided to buy a device. However, that's not enough for me. I want to be able to wave around a handheld meter like a ghost hunter on TV, divining whether the air outside my apartment is safe, or whether my windows are leaking smoke. So, that's where we're kicking off.`}</p>
    <h2 {...{
      "id": "building-the-display"
    }}><a parentName="h2" {...{
        "href": "#building-the-display"
      }}>{`Building the display`}</a></h2>
    <p>{`Parts:`}</p>
    <ul>
      <li parentName="ul">{`16x2 LCD Display`}
        <ul parentName="li">
          <li parentName="ul">{`Specifically the `}<a parentName="li" {...{
              "href": "https://www.amazon.com/OSEPP-16X2SHD-01-Display-Keypad-Shield/dp/B00EOASF72"
            }}>{`OSEPP-LCD-01`}</a>{` keypad shield.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://store.arduino.cc/usa/arduino-uno-rev3"
        }}>{`Arduino Uno R3`}</a></li>
    </ul>
    <p>{`This decision was based on the fact that I had a 16x2 display already lying around, and that I wanted to keep my GPIO pins free for the other parts I'm going to use for this project.`}</p>
    <BlogImage src={display_parts} mdxType="BlogImage">Parts for the Display</BlogImage>
    <p>{`Note - this display is a relatively old model, and it isn't important to use the exact model. You may have to look up a different set of Arduino drivers from the manufacturer if you are building this project on your own.`}</p>
    <h3 {...{
      "id": "getting-started"
    }}><a parentName="h3" {...{
        "href": "#getting-started"
      }}>{`Getting started`}</a></h3>
    <p>{`Assembly is blessedly simple, this time. The keypad/display shield is designed to fit an Arduino, so you can just insert the pins and get rolling.`}</p>
    <BlogImage src={assembled} mdxType="BlogImage">Just stick 'em together</BlogImage>
    <p>{`Now, if you plug the USB cable into your PC, you'll see the display receive power, but it's not going to be functional yet.`}</p>
    <p>{`I don't currently have the Arduino IDE, so I grabbed it from their `}<a parentName="p" {...{
        "href": "https://www.arduino.cc/en/main/OldSoftwareReleases"
      }}>{`Downloads`}</a>{` page`}</p>
    <p>{`I also grabbed the Sample Code from the `}<a parentName="p" {...{
        "href": "https://www.osepp.com/electronic-modules/shields/45-16-2-lcd-display-keypad-shield"
      }}>{`OSEPP website`}</a>{` for my display.`}</p>
    <p>{`I dropped the `}<inlineCode parentName="p">{`buttontest`}</inlineCode>{` code in, and it worked with no problems!`}</p>
    {
      /* Image here */
    }
    <p>{`So, that solves the generic issue of "write to the screen", but the rest of this application is going to be written in Python, running on the Raspberry Pi to take advantage of the drivers that the lovely folks at Adafruit have written.`}</p>
    <p>{`Therefore, I'll need to set the code up to allow me to communicate back and forth between the Arduino and the Python script, sending messages to be displayed, and passing button inputs from the board back to Python.`}</p>
    <h2 {...{
      "id": "coding-for-the-display"
    }}><a parentName="h2" {...{
        "href": "#coding-for-the-display"
      }}>{`Coding for the display`}</a></h2>
    <h3 {...{
      "id": "step-1---hello-world"
    }}><a parentName="h3" {...{
        "href": "#step-1---hello-world"
      }}>{`Step 1 - Hello World`}</a></h3>
    <p>{`I've started by modifying the `}<inlineCode parentName="p">{`buttontest`}</inlineCode>{` script to accept input and output from Serial. Here's the code to receive a message:`}</p>
    <pre {...{
      "className": "language-c"
    }}><code parentName="pre" {...{
        "className": "language-c"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// serial_lcd.pde`}</span>{`

`}<span parentName="code" {...{
          "className": "token macro property"
        }}><span parentName="span" {...{
            "className": "token directive-hash"
          }}>{`#`}</span><span parentName="span" {...{
            "className": "token directive keyword"
          }}>{`include`}</span>{` `}<span parentName="span" {...{
            "className": "token string"
          }}>{`<LiquidCrystal.h>`}</span></span>{`
LiquidCrystal `}<span parentName="code" {...{
          "className": "token function"
        }}>{`lcd`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`8`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`9`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`4`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`5`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`6`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`7`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

String inputString `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`void`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setup`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
lcd`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`begin`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`16`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
Serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`begin`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`9600`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
inputString`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`reserve`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`200`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
lcd`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`setCursor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`void`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`serialEvent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`while`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`Serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`available`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    lcd`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`clear`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    inputString `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'\\0'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// wait a bit for the entire message to arrive`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`delay`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`100`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`while`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`Serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`available`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// display each character to the LCD`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`char`}</span>{` inChar `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`char`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`Serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`read`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      inputString `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+=`}</span>{` inChar`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`for`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`auto`}</span>{` x `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` inputString`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`x `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`==`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'\\n'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        lcd`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`setCursor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        lcd`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`write`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`x`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`So, let's break down what's happening here:`}</p>
    <ul>
      <li parentName="ul">{`I'm setting up the LCD display using the LiquidCrystal drivers, and assigning it to the pins that are currently wired to the display.`}</li>
      <li parentName="ul">{`Then, I declare a buffer string for any serial messages that come in.`}</li>
      <li parentName="ul">{`Next, I'm setting up the display, opening up a Serial connection, and setting the cursor.`}</li>
    </ul>
    <p>{`Next, the serialEvent is set up to listen to any messages coming in over the USB connection. It resets the screen and my string buffer, and then concatenates the whole message into a string. I then iterate through the characters and write them to the display, with bonus logic to handle a newline character to write to the second row.`}</p>
    <p>{`Next, the Python code:`}</p>
    <pre {...{
      "className": "language-py"
    }}><code parentName="pre" {...{
        "className": "language-py"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`# Arduino.py`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` serial
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` time
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` io

arduino `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`Serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'/dev/cu.usbmodem14201'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`9600`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
                        timeout`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` parity`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{`serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`PARITY_EVEN`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` rtscts`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
sio `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` io`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`TextIOWrapper`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`io`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`BufferedRWPair`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`arduino`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` arduino`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`# The sleep is important to let the arduino wake up!`}</span>{`
time`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`sleep`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`

sio`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`write`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'Hello World'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
sio`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`flush`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`Not so bad, right?`}</p>
    <p>{`Here we set up the serial port, targeted at the port my Arduino is using, and use the io wrapper to support sending simple strings.`}</p>
    <p>{`After that, all we have to do is write our string and flush the serial io to send our message along!`}</p>
    <h3 {...{
      "id": "sending-messages-back"
    }}><a parentName="h3" {...{
        "href": "#sending-messages-back"
      }}>{`Sending messages back`}</a></h3>
    <p>{`Since we have a bunch of convenient buttons already attached, I naturally want to be able to use them to send messages back to the main application.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`buttontest`}</inlineCode>{` example gives us a good head start on this boilerplate, but I modified to change the logic to only send a message out on Serial when the most recent message changes. That looks a bit like this:`}</p>
    <pre {...{
      "className": "language-c"
    }}><code parentName="pre" {...{
        "className": "language-c"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// serial_lcd.pde`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`int`}</span>{` last_message `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`int`}</span>{` lcd_key     `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`int`}</span>{` adc_key_in  `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`int`}</span>{` current_line `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
String inputString `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`         `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// a String to hold incoming data`}</span>{`
bool stringComplete `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` false`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// whether the string is complete`}</span>{`
`}<span parentName="code" {...{
          "className": "token macro property"
        }}><span parentName="span" {...{
            "className": "token directive-hash"
          }}>{`#`}</span><span parentName="span" {...{
            "className": "token directive keyword"
          }}>{`define`}</span>{` `}<span parentName="span" {...{
            "className": "token macro-name"
          }}>{`btnRIGHT`}</span>{`  `}<span parentName="span" {...{
            "className": "token expression"
          }}><span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "token macro property"
        }}><span parentName="span" {...{
            "className": "token directive-hash"
          }}>{`#`}</span><span parentName="span" {...{
            "className": "token directive keyword"
          }}>{`define`}</span>{` `}<span parentName="span" {...{
            "className": "token macro-name"
          }}>{`btnUP`}</span>{`     `}<span parentName="span" {...{
            "className": "token expression"
          }}><span parentName="span" {...{
              "className": "token number"
            }}>{`1`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "token macro property"
        }}><span parentName="span" {...{
            "className": "token directive-hash"
          }}>{`#`}</span><span parentName="span" {...{
            "className": "token directive keyword"
          }}>{`define`}</span>{` `}<span parentName="span" {...{
            "className": "token macro-name"
          }}>{`btnDOWN`}</span>{`   `}<span parentName="span" {...{
            "className": "token expression"
          }}><span parentName="span" {...{
              "className": "token number"
            }}>{`2`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "token macro property"
        }}><span parentName="span" {...{
            "className": "token directive-hash"
          }}>{`#`}</span><span parentName="span" {...{
            "className": "token directive keyword"
          }}>{`define`}</span>{` `}<span parentName="span" {...{
            "className": "token macro-name"
          }}>{`btnLEFT`}</span>{`   `}<span parentName="span" {...{
            "className": "token expression"
          }}><span parentName="span" {...{
              "className": "token number"
            }}>{`3`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "token macro property"
        }}><span parentName="span" {...{
            "className": "token directive-hash"
          }}>{`#`}</span><span parentName="span" {...{
            "className": "token directive keyword"
          }}>{`define`}</span>{` `}<span parentName="span" {...{
            "className": "token macro-name"
          }}>{`btnSELECT`}</span>{` `}<span parentName="span" {...{
            "className": "token expression"
          }}><span parentName="span" {...{
              "className": "token number"
            }}>{`4`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "token macro property"
        }}><span parentName="span" {...{
            "className": "token directive-hash"
          }}>{`#`}</span><span parentName="span" {...{
            "className": "token directive keyword"
          }}>{`define`}</span>{` `}<span parentName="span" {...{
            "className": "token macro-name"
          }}>{`btnNONE`}</span>{`   `}<span parentName="span" {...{
            "className": "token expression"
          }}><span parentName="span" {...{
              "className": "token number"
            }}>{`5`}</span></span></span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// read the buttons`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`int`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`read_LCD_buttons`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
 adc_key_in `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`analogRead`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`      `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// read the value from the sensor`}</span>{`
 `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// my buttons when read are centered at these valies: 0, 144, 329, 504, 741`}</span>{`
 `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// we add approx 50 to those values and check to see if we are close`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`adc_key_in `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1000`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` btnNONE`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{` `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// We make this the 1st option for speed reasons since it will be the most likely result`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`adc_key_in `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`50`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`   `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` btnRIGHT`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`adc_key_in `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`195`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` btnUP`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`adc_key_in `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`380`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` btnDOWN`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`adc_key_in `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`555`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` btnLEFT`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`adc_key_in `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`790`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` btnSELECT`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` btnNONE`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// when all others fail, return this...`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`void`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`loop`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  lcd_key `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`read_LCD_buttons`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`lcd_key `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`!=`}</span>{` last_message`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
   last_message `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` lcd_key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
   Serial`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`print`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`lcd_key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`Now we are sending data back over serial as codes ranging from 0-5.`}</p>
    <p>{`We can program python to listen to these messages with this code:`}</p>
    <pre {...{
      "className": "language-py"
    }}><code parentName="pre" {...{
        "className": "language-py"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`# Arduino.py`}</span>{`

last_code `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`''`}</span>{`


`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`def`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`handle_button`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`code`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`global`}</span>{` last_code
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` code `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`==`}</span>{` last_code`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{`
    last_code `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` code
    switcher `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'0'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'RIGHT'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'1'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'UP'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'2'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'DOWN'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'3'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'LEFT'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'4'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'SELECT'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

    msg `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` switcher`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`get`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`code`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'NONE'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    sio`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`write`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-interpolation"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`f'Button: `}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`msg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`'`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    sio`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`flush`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`


`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`while`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`True`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
    new_message `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token builtin"
        }}>{`bytes`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`arduino`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`readline`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`decode`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"utf-8"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'ignore'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`new_message `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`!=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`''`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
        handle_button`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`new_message`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    time`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`sleep`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`.1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`

`}</code></pre>
    <p>{`Now, we can press buttons on the LCD Shield, process those codes in Python, and respond by updating the screen with the code!`}</p>
    <p>{`Since we can also pass new line characters, I can now predictably write to all the available spaces using two 16 character strings joined by a `}<inlineCode parentName="p">{`\\n`}</inlineCode>{` newline character.`}</p>
    <p>{`I'm satisfied with this functionality for now, so stay tuned for part 2 where I'll wire up the air quality sensor!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      